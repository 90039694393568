/**
 * Gutenbergのカラムにcolのクラスを追加
 * @param columns .wp-block-columnsのNode
 * @param columnsItem .wp-block-columnのClassName
 */
export class ColumnsCount {
	constructor(obj) {
		this.columns = obj.columns;
		this.columnsItem = this.columns.querySelectorAll(obj.columnsItem);
		this.reverseTarget = this.columns.querySelector(
			'[style="flex-basis:33.33%"]'
		);
		this.array = [].slice.call(this.columnsItem);

		// 要素の順番を取得
		this.reverseIndex = this.array.indexOf(this.reverseTarget); // 2
		this.init();
	}

	init() {
		const { length } = this.columnsItem;
		if (length > 0) {
			this.columns.classList.add(`-col_${length}`);
		}

		if (this.reverseTarget && this.reverseIndex > 0) {
			this.columns.classList.add('-reverse');
		}
	}
}
