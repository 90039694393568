export class FixedAnchor {
	constructor(obj) {
		this.baselineY = 200; // ビューエリアの上端から200px
		this.tocLinks = obj.tocLinks; // = a.js-fixed-outline-item
		this.sections = obj.sections; // = #js-content-area h2[id], #js-content-area h3[id]
		this.init();
	}

	updateActiveSection() {
		let activeSection = this.sections[0];

		this.sections.forEach((section) => {
			if (section.getBoundingClientRect().top <= this.baselineY) {
				activeSection = section;
			}
		});

		this.tocLinks.forEach((item) => {
			item.classList.remove('-active');
			if (item.getAttribute('href') === `#${activeSection.id}`) {
				item.classList.add('-active');
			}
		});
	}

	init() {
		window.addEventListener('scroll', () => {
			this.updateActiveSection();
		});
		window.addEventListener('resize', () => {
			this.updateActiveSection();
		});
		document.addEventListener('DOMContentLoaded', () => {
			this.updateActiveSection();
		});
	}
}
