export class SmoothScroll {
	constructor(obj) {
		this.header = obj.header;
		this.isScrolling = false;
		this.isPC;
		this._BREAK_POINT = obj.BREAK_POINT !== null ? obj.BREAK_POINT : 1024;
		this._DURATION = obj.DURATION !== null ? obj.DURATION : 1600;
		this.init();
	}

	updateDevice() {
		const width = window.innerWidth;
		if (width >= this._BREAK_POINT) {
			if (this.isPC) return;
			this.isPC = true;
		} else {
			if (!this.isPC) return;
			this.isPC = false;
		}
	}

	changeScrollFlag() {
		setTimeout(() => {
			this.isScrolling = false;
		}, 1200);
	}

	/**
	 * イージング関数.
	 * @param {Number} currentTime 変化が開始してからの経過時間
	 * @param {Number} startValue 初期値
	 * @param {Number} changeValue 完了時の値ではなく、初期値から変化させたい分の値
	 * @param {Number} this._DURATION 変化が完了するまでに要する時間
	 */
	easingEaseOutExpo(currentTime, startValue, changeValue, duration) {
		return (
			changeValue * (-1 * 2 ** ((-10 * currentTime) / duration) + 1) +
			startValue
		);
	}

	updateScrollPosition() {
		const targetIdName = document.getElementById(this.target.replace(/#/g, '')); // 頭の"#"を削除
		if (targetIdName !== null) {
			const move = () => {
				// 実際にスクロールを行う
				const currentTime = Date.now() - startTime;
				const position = this.easingEaseOutExpo(
					currentTime,
					startPos,
					diff,
					this._DURATION
				);

				if (currentTime < this._DURATION) {
					window.scrollTo(0, position);
					window.requestAnimationFrame(move);
				} else {
					window.scrollTo(0, diff + startPos);
				}
			};

			const startPos = window.pageYOffset; // スタート地点
			// const targetPos = $(this.target).offset().top;
			// documentから見た要素の絶対位置取得
			const rect = targetIdName.getBoundingClientRect();
			const scrollTop =
				window.pageYOffset || document.documentElement.scrollTop;
			const targetPos = rect.top + scrollTop; // offset().top;
			const diff = targetPos - startPos - this.offset;
			const startTime = Date.now();
			requestAnimationFrame(move); // 初回呼び出し
		}
	}

	getOffset() {
		return this.header.offsetHeight;
	}

	init() {
		this.updateDevice();

		// URLに#が含まれ、アンカーへリンクしている場合のヘッダー高さ分の調整
		if (
			location.hash !== '' &&
			document.querySelector(location.hash) !== undefined
		) {
			this.target = location.hash;
			this.offset = this.getOffset();
			this.updateScrollPosition();
		}

		// $(window).on('resize', () => {
		//   this.updateDevice();
		// });

		const linkList = document.querySelectorAll('a[href^="#"]');

		for (let i = 0; i < linkList.length; i++) {
			linkList[i].addEventListener('click', (e) => {
				if (this.isScrolling) return false;
				e.preventDefault();

				this.target = e.currentTarget.hash;
				if (this.hash === '') return false; // href="#"などの場合は処理中止

				this.offset = this.getOffset();
				this.updateScrollPosition();
			});
		}
	}
}
