import { ScrollHeader } from './ui/ScrollHeader';
import Screen from './util/Screen';
import { SmoothScroll } from './ui/SmoothScroll';
import { SwitchViewport } from './ui/SwitchViewport';
import { ColumnsCount } from './ui/ColumnsCount';
import { accordion } from './ui/Accordion';
import { TableStyle } from './ui/TableStyle';
import { SlideMenu } from './ui/SlideMenu.js';
import { FixedAnchor } from './ui/FixedAnchor.js';

document.addEventListener('DOMContentLoaded', () => {
	Screen.init();

	const header = document.querySelector('#js-header');

	if (header) {
		new ScrollHeader({
			header
		});
	}
});

window.addEventListener('load', () => {
	// 読み込み時のtransition-property: allのちらつき対策
	document.getElementsByTagName('body')[0].classList.remove('preload');

	// SP スライドメニュー
	if (document.querySelector('#js-slide-menu-trigger')) {
		new SlideMenu({
			menuName: '#js-slide-menu',
			triggerName: '#js-slide-menu-trigger',
			header: '#js-header'
		});
	}

	// スライダー
	const header = document.querySelector('#js-header');

	/**
	 * UI関連
	 */
	// アコーディオン
	accordion();

	// アンカーリンクなどのスムーススクロール
	if (header) {
		new SmoothScroll({
			header, // Default: null
			BREAK_POINT: null, // Default: 768
			DURATION: null // Default: 1200
		});
	}

	/**
	 * レスポンシブ対応関連
	 */
	new SwitchViewport();

	/**
	 * カスタムブロック関連
	 */
	const columns = document.querySelectorAll('.wp-block-columns');
	for (let i = 0; i < columns.length; i++) {
		new ColumnsCount({
			columns: columns[i],
			columnsItem: '.wp-block-column'
		});
	}

	const tableList = document.querySelectorAll('.wp-block-table');
	for (let i = 0; i < tableList.length; i++) {
		new TableStyle(tableList[i]);
	}

	// 追従目次のカレント更新
	const fixedAnchorMenu = document.getElementById('js-fixed-outline');
	if (fixedAnchorMenu) {
		new FixedAnchor({
			tocLinks: fixedAnchorMenu.querySelectorAll('.js-fixed-outline-item'),
			sections: document.querySelectorAll(
				'#js-content-area h2[id]:not(.js-exclude-head), #js-content-area h3[id]:not(.js-exclude-head)'
			)
		});
	}
});
