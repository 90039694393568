import Screen from '../util/Screen';

export class ScrollHeader {
	constructor(obj) {
		this.header = obj.header;
		this.prevY = window.scrollY;

		this.init();
	}

	init() {
		Screen.addScrollObject(this);
	}

	onScroll() {
		if (Screen.getBP(Screen.L)) {
			const currentY = window.scrollY;
			if (currentY < this.prevY) {
				this.header.classList.remove('-hidden');
			} else {
				if (currentY > 0) {
					this.header.classList.add('-hidden');
				}
			}
			this.prevY = currentY;
		}
	}
}
