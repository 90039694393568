import Screen from '../util/Screen.js';

const OPTION = {
	HIDE_STATE_CLASS: '-hide',
	VISIBLE_STATE_CLASS: '-visible',
	ACTIVE_STATE_CLASS: '-active'
};

export class SlideMenu {
	constructor(obj) {
		this.headerMenu = document.querySelector(obj.headerMenu);
		this.menu = document.querySelector(obj.menuName);
		this.trigger = document.querySelector(obj.triggerName);
		this.header = document.querySelector(obj.headr);
		this.overlay = null;

		this.init();
	}

	reset() {
		this.trigger.classList.remove(OPTION.ACTIVE_STATE_CLASS);
		this.menu.classList.remove(OPTION.VISIBLE_STATE_CLASS);
		if (this.overlay) {
			this.overlay.classList.remove(OPTION.ACTIVE_STATE_CLASS);
		}
	}
	toggleMenu() {
		this.trigger.classList.toggle(OPTION.ACTIVE_STATE_CLASS);
		this.menu.classList.toggle(OPTION.VISIBLE_STATE_CLASS);
		if (this.overlay) {
			this.overlay.classList.toggle(OPTION.ACTIVE_STATE_CLASS);
		}
	}
	init() {
		Screen.addResizeObject(this);

		this.trigger.addEventListener('click', (e) => {
			e.preventDefault();
			this.toggleMenu();
		});

		// エリア外判定とし、閉じる
		document.addEventListener('click', () => {
			if (this.menu.classList.contains(OPTION.VISIBLE_STATE_CLASS)) {
				this.toggleMenu();
			}
		});
		// メニュー内とトリガーのクリックはカウントしない
		this.menu.addEventListener('click', (e) => {
			e.stopPropagation();
		});
		this.trigger.addEventListener('click', (e) => {
			e.stopPropagation();
		});
	}
	onResize() {
		if (Screen.getBP(Screen.S)) {
			this.reset();
		}
	}
}
