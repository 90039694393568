export class TableStyle {
	constructor(table) {
		this.table = table;
		this.init();
	}

	init() {
		const tableThead = this.table.querySelector('thead');
		if (tableThead) {
			this.table.classList.add('-fixed');
			const colCount = tableThead.querySelectorAll('th').length;
			if (colCount > 2) {
				this.table.classList.add('-scroll');
			} else if (!this.table.querySelector('.has-fixed-layout')) {
				this.table.classList.add('-scroll');
			}
		}
	}
}
